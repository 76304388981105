import { IStage, IProgram, IPublishedProgram, IPublishedStage } from '../types/models/program-management';
import { IDocument } from '../types/models/document';
import { IStageInvitationResult } from '../types/models/stage-invite';

/**
 * This function is for sorting the stage.
Stages should be sorted by Start Date and Time in an ascending order
Earliest start date and time should be at the top
If there are multiple stages with same Start Date and Time, they will stay in their current position
 * @param a Stage
 * @param b Stage
 */
export const sortStages = (a: IStage, b: IStage) =>
  a.startDate && b.startDate ? new Date(a.startDate).valueOf() - new Date(b.startDate).valueOf() : 0;

/**
 * This function is for sorting the program whose status is draft.
 * Programs with draft status should be sorted by updated date in the descending order
 * @param a Program
 * @param b Program
 */
export const sortDraftProgram = (a: IProgram, b: IProgram) => {
  if (a.updatedAt && b.updatedAt) {
    return new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1;
  }
  return 0;
};

/**
 * This function is for sorting the program whose status is open or close.
 * Compare the two program by the first stage displayed in the program.
 * Then compare two stages by start date, if two stages have the same start date and time, compare the name in alphabetic order
 * for open and close programs. The stage information is always there.
 * @param a Program
 * @param b Program
 */
export const sortProgram = (a: IProgram, b: IProgram) => {
  if (a.stages && b.stages && a.stages.length > 0 && b.stages.length > 0) {
    const sortedStagesA = a.stages.sort(sortStages);
    const sortedStagesB = b.stages.sort(sortStages);
    return sortStages(sortedStagesA[0], sortedStagesB[0]);
  }
  return 0;
};

/**
 * This function is for sorting the messages by date.
 * @param a Message
 * @param b Message
 */
export const sortMessageDate = <T extends { date: string }>(a: T, b: T) => {
  return a.date > b.date ? 1 : a.date < b.date ? -1 : 0;
};

/**
 * This function is for sorting the documents in the document bank
 * @param a
 * @param b
 */
export const sortDocuments = (a: IDocument, b: IDocument) => {
  return new Date(a.date) < new Date(b.date) ? 1 : new Date(a.date) > new Date(b.date) ? -1 : 0;
};

/**
 * This function is for sorting the published stages for dashboard
 * @param a
 * @param b
 */
export const sortPublishedStagesForDashboard = (a: IPublishedStage, b: IPublishedStage) => {
  if (a.startDate && b.startDate) {
    if (new Date(a.startDate) > new Date(b.startDate)) {
      return 1;
    } else if (new Date(a.startDate) < new Date(b.startDate)) {
      return -1;
    } else {
      return a.name.localeCompare(b.name); //compare the name based on the language settings of the browser
    }
  }
  return 0;
};

/**
 * This function is for sorting the published programs for dashboard
 * @param a
 * @param b
 */
export const sortPublishedProgramsForDashboard = (a: IPublishedProgram, b: IPublishedProgram) => {
  if (a.stages && b.stages && a.stages.length > 0 && b.stages.length > 0) {
    const sortedStagesA = a.stages.sort(sortPublishedStagesForDashboard);
    const sortedStagesB = b.stages.sort(sortPublishedStagesForDashboard);
    return sortPublishedStagesForDashboard(sortedStagesA[0], sortedStagesB[0]);
  }
  return 0;
};

export const sortInvite = (a: IStageInvitationResult, b: IStageInvitationResult) => {
  if (a.updatedAt && b.updatedAt) {
    if (new Date(a.updatedAt) > new Date(b.updatedAt)) {
      return 1;
    } else if (new Date(a.updatedAt) < new Date(b.updatedAt)) {
      return -1;
    }
  }
  return 0;
};

export const sortMemoryLookups = (itemToCompare: any, nextItem: any) => {
  if (itemToCompare.displayText < nextItem.displayText) {
    return -1;
  }
  if (itemToCompare.displayText > nextItem.displayText) {
    return 1;
  }
  return 0;
};

export const sortStories = (itemToCompare: any, nextItem: any) => {
  if (itemToCompare.createdOn > nextItem.createdOn) {
    return -1;
  }
  if (itemToCompare.createdOn < nextItem.createdOn) {
    return 1;
  }
  return 0;
};
